import ImageWithShadow from '../../ImageWithShadow/ImageWithShadow';
import './InfoWithPhoto.css';


function InfoWithPhoto({ rtl, photo, className, title, text }) {

    return (
        <div className={`info-with-photo ${rtl ? 'info-with-photo__rtl' : ''} ${className ? className : ''}`}>
            <ImageWithShadow className={'info-with-photo__img'} src={photo} alt="" />
            <div className='info-with-photo__info'>
                {title ?
                    <h4 className='info-with-photo__title'>{title}</h4>
                    : null}
                    {text ? 
                    <p className='info-with-photo__text'>{text}</p>
                    : null}
            </div>
        </div>
    );
}

export default InfoWithPhoto;
