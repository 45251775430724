
import { AdvantagesCards } from '../../../assets/utils/constants';
import './Advantages.css';


function Advantages() {

    return (
        <div className="advantages">
            {AdvantagesCards.map((item, i) => (
                <div className='advantages__card' key={`advantages__card${i}`}>
                    <div className='advantages__card-icon-box'>
                        {item.icon({ mainClassName: 'advantages__card-icon', fillClassName: 'advantages__card-icon-fill' })}
                    </div>
                    <p className='advantages__card-text'>{item.text}</p>
                </div>
            ))}
        </div>
    );
}

export default Advantages;
