
import { Link } from 'react-router-dom';
import { MAIN_URL } from '../../../../assets/utils/constants';
import './TypeCard.css';


function TypeCard({ item }) {

    return (
        <Link className={`type-card`} to={`/ceiling-type/${item.translit_name}`}>
            <p className='type-card__title'>{item.small_title}</p>
            <img className='type-card__img' src={`${MAIN_URL}/get-file/${item.photo_path}`} alt={item.small_title} />
            <p className='type-card__link' >Подробнее</p>
            <p className='type-card__price'>{item.price}</p>
        </Link>
    );
}

export default TypeCard;
