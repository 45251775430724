

import ContactData from './ContactData/ContactData';
import './Contacts.css';

function Contacts() {
    return (
        <div className="contacts">
            <div className='contacts__block-with-padding'>
                <ContactData isContactPage={true} />
            </div>
        </div>
    );
}

export default Contacts;
