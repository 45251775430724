import './AboutUs.css';
import InfoWithPhoto from './InfoWithPhoto/InfoWithPhoto';

import img_1 from '../../assets/images/About/about-1.webp'
import img_2 from '../../assets/images/About/about-2.webp'
import ContactData from '../Contacts/ContactData/ContactData';
import Slider from './Slider/Slider';
import { OUR_WORKS } from '../../assets/utils/our_works';
import { CERTIFICATES } from '../../assets/utils/certificates';
import OurClients from './OurClients/OurClients';
import { OUR_CLIENTS } from '../../assets/utils/our_clients';



function AboutUs() {

    return (
        <div className="about-us">
            <InfoWithPhoto
                photo={img_1}
                title={'ArtMastePro - это дружная команда профессионалов!'}
                text={`С 2014 года наша компания успешно реализовывает проекты различного уровня сложности и является лидером на рынке натяжных потолков. У нас работают профессионалы в своём деле с опытом работы более 18 лет.
                
За эти 9 лет мы успешно выполнили более 10.000 проектов. И мы гордимся этим! С нами сотрудничали такие крупные клиенты как: Life Fitness, Black Star Fitness, Work hard sleep well.`}
            />
            <InfoWithPhoto
                className="about-us__info-with-photo"
                rtl={true}
                photo={img_2}
                text={`Мы несём гарантийную ответственность за каждый метр и абсолютно уверены в качестве применяемых материалов, комплектующих и монтажа.
                
Все полотна, которые мы устанавливаем, прошли сертификацию на территории РФ и признаны безопасными для здоровья и окружающей среды. Мы предлагаем потолки, прошедшие сертификацию в Государственном Центре Гигиены и Эпидемиологии, 100% без запаха и вредных веществ.`}
            />
            {/* <div className='about-us__slider-box'>
                <h3 className='about-us__slider-title'>Наши работы</h3>
                <Slider
                    className={'about-us__slider'}
                    photosArray={OUR_WORKS} />
            </div> */}
            <OurClients title={'Наши клиенты'} cards={OUR_CLIENTS} />
            <OurClients title={'Наши работы'} cards={OUR_WORKS} linkToAll={
                {
                    title: 'Посмотреть все работы',
                    path: '/all-works'
                }
            } />
            <div className='about-us__slider-box'>
                <h3 className='about-us__slider-title'>Сертификаты</h3>
                <Slider
                    className={'about-us__slider'}
                    photosArray={CERTIFICATES} />
            </div>
            <ContactData />
        </div>
    );
}

export default AboutUs;
