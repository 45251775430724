
import AllMaterials from '../Materials/AllMaterials/AllMaterials';
import AllTypes from './AllTypes/AllTypes';
import './Types.css';


function Types() {

    return (
        <div className="types">
            <div className='types__block-with-padding'>
                <AllTypes isDefault={true} />
            </div>
            <div className='types__block-with-padding'>
                <AllMaterials />
            </div>
        </div>
    );
}

export default Types;
