import { useEffect, useRef } from "react";
import Form from "../Form/Form";



import "./FormPopup.css";

function FormPopup({ isOpen, setOpen, text, popupName, formObj, setFormObj }) {


    const overlay = useRef();

    function handleClose() {
        setOpen(prevState => ({
            ...prevState,
            [popupName]: false,
        }))
        setTimeout(() => {
            setFormObj(undefined)
        }, 300);

    }

    useEffect(() => {
        function handleOverlayClose(evt) {
            if (overlay.current === evt.target) {
                handleClose();
            }
        }

        document.addEventListener("mousedown", handleOverlayClose);
        return () => {
            document.removeEventListener("mousedown", handleOverlayClose);
        };
    });



    return (
        <div
            className={`form-popup ${isOpen ? "form-popup_opened" : ""}`}
            ref={overlay}
        // onTouchStart={handleClose}
        >

            <div className="form-popup__container">
                <button
                    className="form-popup__close-button"
                    type="button"
                    aria-label="Закрыть попап"
                    onClick={handleClose}
                >
                    <svg
                        className="form-popup__close-button-icon"
                        width="14"
                        height="14"
                        viewBox="0 0 14 14"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            className="form-popup__close-button-icon-stroke"
                            d="M11.6836 2.33398L2.33194 11.6856"
                            stroke="white"
                            strokeWidth="2"
                        />
                        <path
                            className="form-popup__close-button-icon-stroke"
                            d="M2.33203 2.33398L11.6837 11.6856"
                            stroke="white"
                            strokeWidth="2"
                        />
                    </svg>
                </button>
                {formObj ?
                    <>
                        <h3 className="form-popup__title">{formObj.title}</h3>
                        <p className="form-popup__subtitle">Оставьте заявку на {formObj.title.toLowerCase()} и мы обязательно свяжемся с вами в ближайшее время</p>
                        <Form
                            className={'form-popup__form'}
                            col_key={formObj.col_key}
                            col_value={formObj.col_value}
                            col_type={formObj.col_type}
                            onSubmitted={handleClose}
                        />
                    </>

                    : null}

            </div>
        </div >
    );
}

export default FormPopup;