
import Button from '../../Button/Button';
import './About.css';

import logo from '../../../assets/images/Main/About/logo.webp'

function About() {
  return (
    <div className="about">
      <div className='about__icon-box'>
        <img src={logo} className={'about__icon'} alt="" />
      </div>
      <div className='about__info'>
        <h3 className='about__title'>ArtMasterPro</h3>
        <p className='about__subtitle'>Мы работаем без посредников. Сами отвечаем на звонки. Сами приезжаем на замер. Сами натягиваем потолок и подключаем светильники. Наша репутация — наше все, поэтому «держим планку» на уровне, до которого можем дотянуться.</p>
        <Button
          text={'Подробнее'}
          className={'about__btn'}
          path={'/about-us'}
          isBgTransparent={true}
        />
      </div>
    </div>
  );
}

export default About;
