import { useState } from 'react';
import { Link } from 'react-router-dom';
import mainApi from '../../../assets/api/MainApi';
import { AddIcon, CloseIcon } from '../../../assets/icons/icons';
import Button from '../../Button/Button';
import Input from '../../Form/Input/Input';
import Stars from '../AllReviews/Stars/Stars';
import './NewReview.css';



function NewReview() {

    const [form, setForm] = useState({
        name: '',
        text: '',
        photos: [],
        photoURL: [],
        raiting: 4,
    })

    function handleFormChange(e) {
        const value = e.target.value
        const name = e.target.name

        switch (name) {

            default:
                setForm(prevValue => ({
                    ...prevValue,
                    [name]: value,
                }))
                break;
        }

    }


    const [emptyBlocks, setEmptyBlocks] = useState(Array(5).fill(" "))

    function handlePhotoChange(evt, values) {
        const files = Array.from(evt.target.files);
        const newFiles = [...values.photos, ...files];
        setForm(prevState => ({
            ...prevState,
            photos: newFiles.length > 5 ? newFiles.slice(0, 5) : newFiles
        }));

        let photos = [];
        files.forEach((file) => {
            let reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onloadend = function () {
                photos.push(reader.result);
                let newPhotos = [...values.photoURL, ...photos];
                setForm(prevState => ({
                    ...prevState,
                    photoURL: newPhotos.length > 5 ? newPhotos.slice(0, 5) : newPhotos
                }));
            };
        });

        setEmptyBlocks(
            Array(5 - newFiles.length - 1 < 0 ? 0 : 5 - newFiles.length - 1).fill(
                " "
            )
        );
    }

    function handleRemovePhoto(index, values) {
        const photos = values.photos.slice();
        const photosURL = values.photoURL.slice();
        const newPhotos = photos.filter((item, i) => i !== index);
        const newPhotosURL = photosURL.filter((item, i) => i !== index);
        setForm(prevState => ({
            ...prevState,
            photos: newPhotos,
            photoURL: newPhotosURL,
        }));
        setEmptyBlocks(Array(5 - newPhotos.length - 1).fill(" "));
    }

    function handleRatingSelect(raiting) {
        setForm(prevState => ({
            ...prevState,
            raiting: raiting,
        }))
    }

    const [isPreloaderVisible, setPreloaderVisible] = useState(false)
    function handleSend() {
        console.log(form)

        setPreloaderVisible(true)
        let data = new FormData();
        if (!form.noPhoto) {
            form.photos.forEach((photo) => data.append("photos", photo));
        }

        data.append(
            "data",
            JSON.stringify({
                score: form.raiting.toString(),
                name: form.name,
                text: form.text,
            })
        );

        mainApi
            .sendReview({ data })
            .then((res) => {
                setForm({
                    name: '',
                    text: '',
                    photos: [],
                    photoURL: [],
                    raiting: 4,
                })
            })
            .catch((err) => {

            })
            .finally(() => {
                setPreloaderVisible(false)
            });

    }

    return (
        <div className="new-review">
            <div className='new-review__heading'>
                <h3 className='new-review__title'>Ваш отзыв</h3>
                <Link className='new-review__back' to={'/reviews/all'}>
                    Назад
                </Link>
            </div>
            <div className='new-review__form'>
                <Input
                    index={1}
                    label="Имя"
                    value={form.name}
                    name="name"
                    type="text"
                    handleChange={handleFormChange}
                    isRequired={true}
                />
                <Input
                    index={1}
                    label="Отзыв"
                    value={form.text}
                    name="text"
                    type="text"
                    handleChange={handleFormChange}
                    isRequired={true}
                    isTextArea={true}
                />
                <div className='new-review__input-box'>
                    <div className="new-review__photo-box">
                        <p className={`new-review__input-label`}>
                            Добавьте фото:
                        </p>
                        <div className={`new-review__photo-grid-box`}>
                            <div className="new-review__photo-grid">
                                {form.photoURL &&
                                    form.photoURL.length > 0
                                    ? form.photoURL.map((image, i) => (
                                        <div className="new-review__photo-item" key={i}>
                                            <img
                                                className="new-review__photo"
                                                src={image}
                                                alt={''}
                                            />
                                            <button
                                                className="new-review__remove-photo-button"
                                                type="button"
                                                aria-label="Удалить фотографию"
                                                onClick={() =>
                                                    handleRemovePhoto(i, form)
                                                }
                                            >
                                                <CloseIcon mainClassName={'new-review__remove-icon'} strokeClassName={'new-review__remove-icon-stroke'} />
                                                {/* {REMOVE_BUTTON_ICON} */}
                                            </button>
                                        </div>
                                    ))
                                    : null}
                                {
                                    form.photos &&
                                        form.photos.length < 5 ? (
                                        <div className="new-review__input-image-box">
                                            <label
                                                className="new-review__input-image-label"
                                                htmlFor="photo"
                                            >
                                                <input
                                                    className="new-review__input-image"
                                                    id="photo"
                                                    name="photo"
                                                    type="file"
                                                    accept="image/jpg, image/jpeg, image/webp"
                                                    multiple
                                                    onChange={(evt) =>
                                                        handlePhotoChange(evt, form)
                                                    }
                                                />
                                                <AddIcon mainClassName={'new-review__image-add-icon'} strokeClassName={'new-review__image-add-icon-stroke'} />
                                                {/* {ADD_ITEM_ICON} */}
                                            </label>
                                        </div>
                                    ) : null}
                                {/* {
                                    form.photos &&
                                        form.photos.length < 4
                                        ? emptyBlocks.map((el, i) => (
                                            <div
                                                className="new-review__photo-container"
                                                key={`Empty${i}`}
                                            ></div>
                                        ))
                                        : null} */}
                            </div>
                        </div>
                        <p className='new-review__image-limit'>до 5 изображений</p>
                    </div>
                </div>
                <div className='new-review__input-box'>
                    <p className='new-review__input-label'>Ваша оценка компании:</p>
                    <Stars raiting={form.raiting} selectValue={handleRatingSelect} />
                </div>
                <Button
                    className={'new-review__send'}
                    isBgContrast={true}
                    text={'Отправить отзыв'}
                    isInactive={form.name && form.text ? false : true}
                    onClick={handleSend}
                    isPreloaderVisible={isPreloaderVisible}
                />
            </div>
        </div>
    );
}

export default NewReview;
