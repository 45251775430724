import { MAIN_URL } from '../../../assets/utils/constants';
import Button from '../../Button/Button';
import ImageWithShadow from '../../ImageWithShadow/ImageWithShadow';
import './ServiceInfo.css';


function ServiceInfo({ item, openFormPopup }) {


    return (
        <div className="type-info">
            <div className='type-info__texts'>
                <h3 className='type-info__title'>{item.full_title}</h3>
                <p className='type-info__text'>{item.description}</p>
                <p className='type-info__price'>{item.price}</p>
                <Button
                    text={item.button_text}
                    className="type-info__btn"
                    isBgContrast={true}
                    onClick={() => {
                        openFormPopup({
                            title: item.popup_title,
                            col_key: '_id',
                            col_value: item._id,
                            col_type: 'services',
                        })
                    }}
                />
            </div>
            <ImageWithShadow className={'type-info__image'} src={`${MAIN_URL}/get-file/${item.photo_path}`} alt="" />
        </div>
    );
}

export default ServiceInfo;
