import { Link } from 'react-router-dom';
import MiniPreloader from '../MiniPreloader/MiniPreloader';
import './Button.css';




function Button({
    className,
    text,
    onClick,
    path,
    leftIcon,
    children,
    isInactive,
    isPreloaderVisible,
    isBgContrast,
    isBgTransparent,

}) {
    return (
        <>
            {path ?
                <Link className={`button ${isBgContrast ? 'button_type_contrast' : ''} ${isBgTransparent ? 'button_type_transparent' : ''} ${isInactive ? 'button_type_inactive' : ''} ${isPreloaderVisible ? 'button_type_loading' : ''} ${className ? className : ''}`} to={path} onClick={onClick}>

                    {isPreloaderVisible ?
                        <MiniPreloader isLinkColor={isBgContrast} />
                        :
                        <>
                            {leftIcon ? leftIcon({ mainClassName: `button__icon ${!text ? 'button__icon_no-text' : ''}`, strokeClassName: 'button__icon-stroke', fillClassName: 'button__icon-fill' }) : null}
                            {text}
                            {children}
                        </>
                    }

                </Link >
                :
                <button className={`button ${isBgContrast ? 'button_type_contrast' : ''} ${isBgTransparent ? 'button_type_transparent' : ''} ${isInactive ? 'button_type_inactive' : ''} ${isPreloaderVisible ? 'button_type_loading' : ''} ${className ? className : ''}`} type='button' onClick={onClick}>
                    {isPreloaderVisible ?
                        <MiniPreloader isLinkColor={isBgContrast} />
                        :
                        <>
                            {leftIcon ? leftIcon({ mainClassName: `button__icon ${!text ? 'button__icon_no-text' : ''}`, strokeClassName: 'button__icon-stroke', fillClassName: 'button__icon-fill' }) : null}
                            {text}
                            {children}
                        </>
                    }
                </button >
            }
        </>

    );
}

export default Button;