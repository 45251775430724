import { NavLink } from "react-router-dom"
import { MAIN_LINKS } from "../../../assets/utils/constants"

import '../Header.css';


function HeaderLinks({ isMenu, onClick }) {
    return (
        <>
            {MAIN_LINKS.map((item, i) => (
                <NavLink
                    className={({ isActive }) =>
                        "header__link" + (isActive ? " header__link_active" : "")
                    }
                    key={`HeaderLinks_link${i}${isMenu ? 'form-menu' : 'default'}`}
                    onClick={() => {
                        if (onClick) {
                            onClick()
                        }
                    }}
                    to={`/${item.path}`}>
                    {item.name}
                </NavLink>
            ))}


        </>
    )
}

export default HeaderLinks