
import { Link } from 'react-router-dom';
import { MAIN_URL } from '../../../../assets/utils/constants';
import './ServicesCard.css';


function ServicesCard({ item }) {

    return (
        <Link className={`service-card`} to={`/ceiling-service/${item.translit_name}`}>
            <p className='service-card__title'>{item.small_title}</p>
            <img className='service-card__img' src={`${MAIN_URL}/get-file/${item.photo_path}`} alt={item.small_title} />
            <p className='service-card__link' >Подробнее</p>
        </Link>
    );
}

export default ServicesCard;
