
import Button from '../Button/Button';
import ContactData from '../Contacts/ContactData/ContactData';
import AllMaterials from '../Materials/AllMaterials/AllMaterials';
import AllServices from '../Services/AllServices/AllServices';
import AllTypes from '../Types/AllTypes/AllTypes';
import About from './About/About';
import Advantages from './Advantages/Advantages';
import Banner from './Banner/Banner';
import Help from './Help/Help';
import './Main.css';

function Main({ openFormPopup }) {
    return (
        <div className="main">
            <div className='main__block-with-padding'>
                <Banner openFormPopup={openFormPopup} />
            </div>
            <div className='main__block-with-padding'>
                <Advantages />
            </div>
            <About />
            <div className='main__block-with-padding'>
                <AllTypes />
            </div>
            <div className='main__block-with-padding'>
                <AllMaterials />
                <Button
                    className={'main__btn'}
                    isBgContrast={true}
                    text="Вызвать замерщика"
                    onClick={() => {
                        openFormPopup({
                            title: 'Вызов замерщика',
                            col_key: 'translit_name',
                            col_value: 'vizov-zamershika',
                            col_type: 'base_tasks',
                        })
                    }}
                />
            </div>
            <div className='main__block-with-padding'>
                <AllServices />
                <Button
                    className={'main__btn'}
                    isBgContrast={true}
                    text="Вызвать специалиста"
                    onClick={() => {
                        openFormPopup({
                            title: 'Вызов специалиста',
                            col_key: 'translit_name',
                            col_value: 'vizov-specialista',
                            col_type: 'base_tasks',
                        })
                    }}
                />
            </div>
            <Help />
            <div className='main__block-with-padding'>
                <ContactData />
            </div>
        </div>
    );
}

export default Main;
