

import { ClockIcon, ContactsPhoneIcon, MailIcon } from '../../../assets/icons/icons';
import { CONTACT_EMAIL, CONTACT_PHONES } from '../../../assets/utils/constants';
import './ContactData.css';

import image from '../../../assets/images/Contacts/ContactsData/contacts.webp'

function ContactData({ isContactPage }) {
    return (
        <div className={`contact-data ${!isContactPage ? 'contact-data__block' : ''}`}>
            <div className='contact-data__info'>
                <h3 className='contact-data__title'>Наши контакты</h3>
                <div className='contact-data__items'>
                    <div className='contact-data__item'>
                        <div className='contact-data__item-icon-box'>
                            <ClockIcon mainClassName={'contact-data__item-icon'} strokeClassName={'contact-data__item-icon-stroke'} />
                        </div>
                        <div className='contact-data__item-texts'>
                            <p className='contact-data__item-text'>Понедельник - Суббота</p>
                            <p className='contact-data__item-text'>с 10:00 до 20:00</p>
                        </div>
                    </div>
                    <div className='contact-data__item'>
                        <div className='contact-data__item-icon-box'>
                            <ContactsPhoneIcon mainClassName={'contact-data__item-icon'} strokeClassName={'contact-data__item-icon-stroke'} />
                        </div>
                        <div className='contact-data__item-texts'>
                            {CONTACT_PHONES.map((item, i) => (
                                <a className='contact-data__item-text' href={`tel:${item.number}`} key={`contact-data__item-contact${i}`}>{item.display_value}</a>
                            ))}
                        </div>
                    </div>
                    <div className='contact-data__item'>
                        <div className='contact-data__item-icon-box'>
                            <MailIcon mainClassName={'contact-data__item-icon'} strokeClassName={'contact-data__item-icon-stroke'} />
                        </div>
                        <div className='contact-data__item-texts'>
                            <a className='contact-data__item-text' href={`mailto:${CONTACT_EMAIL}`}>{CONTACT_EMAIL}</a>
                        </div>
                    </div>
                </div>
            </div>
            <img className='contact-data__img' src={image} alt="" />
        </div>
    );
}

export default ContactData;
