
import MaterialCard from './MaterialCard/MaterialCard';
import './MaterialCards.css';


function MaterialCards({ isAll, cards }) {

    return (
        <div className={`material-cards ${isAll ? 'material-cards__all' : ''}`}>
            {cards && cards.length > 0 ?
                cards.map((item, i) => (
                    <MaterialCard item={item} />
                ))
                : null}
        </div>
    );
}

export default MaterialCards;
