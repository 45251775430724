
import AllMaterials from '../Materials/AllMaterials/AllMaterials';
import AllServices from './AllServices/AllServices';
import './Services.css';


function Services() {

    return (
        <div className="services">
            <div className='services__block-with-padding'>
                <AllServices isDefault={true} />
            </div>
            {/* <div className='services__block-with-padding'>
                <AllMaterials />
            </div> */}
        </div>
    );
}

export default Services;
