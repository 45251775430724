import { UserIcon } from '../../../../assets/icons/icons';
import './ReviewCard.css';

import 'moment/locale/ru'  // without this line it didn't work
import moment from 'moment-timezone';
import Stars from '../../AllReviews/Stars/Stars';
import { MAIN_URL } from '../../../../assets/utils/constants';
moment.locale('ru')


function ReviewCard({ item, setFullScreenSrc }) {

    return (
        <div className={`review-card`}>
            <div className='review-card__heading'>
                <div className='review-card__user'>
                    <div className='review-card__user-img-box'>
                        <UserIcon mainClassName={'review-card__user-img-placeholder'} fillClassName={'review-card__user-img-placeholder-fill'} />
                    </div>
                    <div className='review-card__user-info'>
                        <p className='review-card__user-name'>{item.name}</p>
                        <p className='review-card__date'>{moment(item.utc_datetime).fromNow()}</p>
                    </div>
                </div>
                <Stars raiting={item.score} />
            </div>
            <p className='review-card__text'>{item.text}</p>
            {item.photos && item.photos.length > 0 ?
                <div className='review-card__photos'>
                    {item.photos.map((item, i) => (
                        <img className='review-card__photo' src={`${MAIN_URL}/get-file/${item}`} alt='' onClick={() => {
                            setFullScreenSrc(`${MAIN_URL}/get-file/${item}`)
                        }} />
                    ))}
                </div>
                : null}
        </div>
    );
}

export default ReviewCard;
