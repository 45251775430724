import { CarIcon, KeysIcon, PeopleIcon, WalletIcon } from "../icons/icons"




export const CONTACT_PHONES = [
    {
        display_value: '+7 (926) 305-55-05',
        number: '79263055505'
    },
    {
        display_value: '+7 (926) 405-55-05',
        number: '79264055505'
    },
]

export const MAIN_CONTACT_PHONE = CONTACT_PHONES[0]

export const CONTACT_EMAIL = 'info@artmasterpro.ru'

export const MAIN_LINKS = [
    {
        name: 'О Нас',
        path: 'about-us',
    },
    {
        name: 'Виды потолков',
        path: 'ceiling-types',
    },
    {
        name: 'Услуги',
        path: 'ceiling-services',
    },
    {
        name: 'Контакты',
        path: 'contacts',
    },
    {
        name: 'Отзывы',
        path: 'reviews/all',
    },
]


export const AdvantagesCards = [
    {
        text: 'От 150 рублей/м²',
        icon: WalletIcon,
    },
    {
        text: 'Без посредников',
        icon: KeysIcon,
    },
    {
        text: 'Выезд в этот же день',
        icon: CarIcon,
    },
    {
        text: 'Профессиональные сотрудники',
        icon: PeopleIcon,
    },
]

export const MAIN_URL = "https://app.artmasterpro.ru"

