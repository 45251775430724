


import { useState } from 'react';
import mainApi from '../../assets/api/MainApi';
import Button from '../Button/Button';
import './Form.css';
import Input from './Input/Input';
import { sendMetriс } from '../../assets/utils/utils';

function Form({ className, col_key, col_value, col_type, onSubmitted }) {
    const [form, setForm] = useState({
        name: '',
        phone: '',
        time: '',
    })

    const [formValid, setFormValid] = useState({
        phone: {},
    })


    function handleFormChange(e) {
        const value = e.target.value
        const name = e.target.name

        switch (name) {

            case 'phone':
                let inputValue = e.target.value.replace(/\D/g, '')
                let formattedInputValue = '';
                if (!inputValue) {
                    setForm(prevValue => ({
                        ...prevValue,
                        phone: '',
                    }))
                    setFormValid(prevValue => ({
                        ...prevValue,
                        phone: {
                            errorMassage: 'Можно вводить только цифры',
                            validState: false
                        },
                    }))
                }
                else {
                    if (['7', '8', '9'].indexOf(inputValue[0]) > -1) {
                        setFormValid(prevValue => ({
                            ...prevValue,
                            phone: {
                                errorMassage: '',
                                validState: false
                            },
                        }))
                        if (inputValue[0] === '9') inputValue = '7' + inputValue;

                        let firstSimbols = (inputValue[0] === '8') ? '8' : '+7';
                        formattedInputValue = firstSimbols + ' '

                        if (inputValue.length > 1) {
                            formattedInputValue += '(' + inputValue.substring(1, 4)
                        }
                        if (inputValue.length >= 5) {
                            formattedInputValue += ') ' + inputValue.substring(4, 7)
                        }
                        if (inputValue.length >= 8) {
                            formattedInputValue += '-' + inputValue.substring(7, 9)
                        }
                        if (inputValue.length >= 10) {
                            formattedInputValue += '-' + inputValue.substring(9, 11)
                        }
                        if (inputValue.length >= 11) {
                            setFormValid(prevValue => ({
                                ...prevValue,
                                phone: {
                                    errorMassage: '',
                                    validState: true
                                },
                            }))
                        } else {

                            setFormValid(prevValue => ({
                                ...prevValue,
                                phone: {
                                    errorMassage: '',
                                    validState: false
                                },
                            }))
                        }
                    } else {
                        formattedInputValue = '+' + inputValue.substring(0, 16)
                        if (inputValue.length >= 11) {
                            setFormValid(prevValue => ({
                                ...prevValue,
                                phone: {
                                    errorMassage: '',
                                    validState: true
                                },
                            }))
                        } else {
                            setFormValid(prevValue => ({
                                ...prevValue,
                                phone: {
                                    errorMassage: '',
                                    validState: false
                                },
                            }))
                        }
                    }


                    setForm(prevValue => ({
                        ...prevValue,
                        phone: formattedInputValue,
                    }))
                }
                break;

            default:
                setForm(prevValue => ({
                    ...prevValue,
                    [name]: value,
                }))
                break;
        }

    }

    function handlePhoneDelite(e) {
        if (e.keyCode === 8 && e.target.value.replace(/\D/g, '').length === 1) {
            setForm(prevValue => ({
                ...prevValue,
                phone: '',
            }))
        }
        if (e.keyCode === 8 && e.target.value.replace(/\D/g, '').length < 11) {
            // setForm({
            //     errorMassage: '',
            //     validState: false
            // });
        }

    }

    const isValid = form.name && form.time && form.phone ? formValid.phone.validState : false

    const [isPreloaderVisible, setPreloaderVisible] = useState(false)
    function handleSubmit() {
        setPreloaderVisible(true)
        sendMetriс('reachGoal','SUBMIT_FORM')
        mainApi.sendContactForm({
            name: form.name,
            phone: form.phone && formValid.phone.validState ? form.phone.replace(/\D/g, '') : '',
            time: form.time,
            col_key: col_key,
            col_value: col_value,
            col_type: col_type,
        })
            .then((res) => {
                console.log(res)
                
            })
            .catch((err) => {
                console.log(err)
            })
            .finally(() => {
                setForm({
                    name: '',
                    email: '',
                    phone: '',
                    message: '',
                })
                setPreloaderVisible(false)
                if (onSubmitted) onSubmitted()
            })
    }

    return (
        <div className={`form ${className ? className : ''}`}>
            <div className='form__inputs'>
                <Input
                    index={1}
                    label="Имя"
                    value={form.name}
                    name="name"
                    type="text"
                    handleChange={handleFormChange}
                    isRequired={true}
                />
                <Input
                    index={1}
                    label="Номер телефона"
                    value={form.phone}
                    name="phone"
                    type="text"
                    handleChange={handleFormChange}
                    isRequired={true}
                    onKeyDown={handlePhoneDelite}
                />
                <Input
                    index={1}
                    label="Удобное время для связи"
                    value={form.time}
                    name="time"
                    type="text"
                    handleChange={handleFormChange}
                    isRequired={true}
                />
            </div>

            <Button
                text={'Оставить заявку'}
                isBgContrast={true}
                isInactive={!isValid}
                onClick={handleSubmit}
                isPreloaderVisible={isPreloaderVisible}
            />
        </div>
    );
}

export default Form;
