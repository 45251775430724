
import { Link } from 'react-router-dom';
import { getMatirealIcon } from '../../../../assets/icons/matirealIcons';
import { MAIN_URL } from '../../../../assets/utils/constants';
import './MaterialCard.css';


function MaterialCard({ item }) {
    const Icon = getMatirealIcon(item.svg_type)
    return (
        <Link className={`material-card`} to={`/ceiling-material/${item.translit_name}`}>
            <Icon mainClassName="material-card__icon" />
            <p className='material-card__title'>{item.small_title}</p>

        </Link>
    );
}

export default MaterialCard;
