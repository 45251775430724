
import { Link } from 'react-router-dom';
import { OUR_CLIENTS } from '../../../assets/utils/our_clients';
import ClientsCards from './ClientsCards/ClientsCards';
import './OurClients.css';


function OurClients({ title, cards, linkToAll }) {

    return (
        <div className="clients">
            <h3 className='clients__title'>{title}</h3>
            <ClientsCards cards={cards} />
            {linkToAll ? <Link className='clients__link' to={linkToAll.path}>{linkToAll.title}</Link> : null}
        </div>
    );
}

export default OurClients;
