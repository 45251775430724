import './MenuPopup.css';
import React from "react";
import HeaderLinks from '../HeaderLinks/HeaderLinks';
import { CloseIcon } from '../../../assets/icons/icons';
import { CONTACT_PHONES } from '../../../assets/utils/constants';







function MenuPopup({ isOpen, setOpen, popupName, children }) {

    function handleClose() {
        setOpen(prevState => ({
            ...prevState,
            [popupName]: false,
        }))
    }

    return (
        <>

            <div className={`menu-popup-top ${isOpen ? 'menu-popup_active' : ''}`}>
                <div className={`menu-popup__container ${isOpen ? 'menu-popup__container_active' : ''}`}>
                    <button className='menu-popup__close' type='button' onClick={handleClose}>
                        <CloseIcon mainClassName={'menu-popup__close-icon'} strokeClassName={'menu-popup__close-icon-stroke'} />
                    </button>
                    <div className='menu-popup__links'>
                        <HeaderLinks isMenu={true} onClick={handleClose} />
                    </div>
                    <div className='menu-popup__phones'>
                        {CONTACT_PHONES.map((item, i) => (
                            <a className='menu-popup__phone' href={`tel:${item.number}`} key={`header__contact${i}`}>{item.display_value}</a>
                        ))}
                    </div>

                </div>
                <div className={`menu-popup__background ${isOpen ? 'menu-popup__background_active' : ''}`} onClick={() => {
                    handleClose()

                }}>

                </div>


            </div>
        </>

    );
}

export default MenuPopup;