import React, { useState } from 'react';
import { CloseIcon } from '../../assets/icons/icons';
import ImageWithShadow from '../ImageWithShadow/ImageWithShadow';
import './FullScreenImage.css';

const FullScreenImage = ({ src, setSrc }) => {


    return (
        <div className={`full-screen-image ${src ? 'full-screen-image_active' : ''}`}>
            {src? <ImageWithShadow className={'full-screen-image__img'} src={src} alt="" /> : null }
            <div className='full-screen-image__bg' onClick={() => {
                setSrc(undefined)
            }}></div>
            <div className='full-screen-image__close' onClick={() => {
                setSrc(undefined)
            }}>
                <CloseIcon mainClassName={'full-screen-image__close-icon'} strokeClassName={'full-screen-image__close-icon-stroke'} />
            </div>

        </div>


    );
};

export default FullScreenImage;