import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import mainApi from '../../assets/api/MainApi';

import MiniPreloader from '../MiniPreloader/MiniPreloader';

import './Matereal.css';
import MaterealInfo from './MaterealInfo/MaterealInfo';
import AnotherMaterials from '../Materials/AnotherMaterials/AnotherMaterials';
import AllTypes from '../Types/AllTypes/AllTypes';


function Matereal({openFormPopup}) {

    const { translit_name } = useParams()
    const [isPreloaderVisible, setPreloaderVisible] = useState(true)
    const [item, setItem] = useState(undefined)

    useEffect(() => {
        if (!translit_name) return
        setPreloaderVisible(true)
        mainApi.getItem({ main_type: 'materials', type: 'translit_name', value: translit_name })
            .then((res) => {
                console.log(res)
                setItem(res)
            })
            .catch((err) => {
                console.log(err)
            })
            .finally(() => {
                setPreloaderVisible(false)
            })

    }, [translit_name])

    return (
        <div className="material">
            {isPreloaderVisible ?
                <div className='material__preloader'>
                    <MiniPreloader isBig={true} />
                </div>
                :
                <>
                    {item ?
                        <>
                            <MaterealInfo openFormPopup={openFormPopup} item={item} />
                            <AnotherMaterials exclude_id={item._id} />
                            <AllTypes />
                        </>

                        :
                        <div className='material__no-product'>
                            <p className='material__no-product-title'>Похоже материала {translit_name} не существует</p>
                        </div>
                    }

                </>}

        </div>
    );
}

export default Matereal;
