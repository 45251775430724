

import Form from '../../Form/Form';
import './Help.css';

import img from '../../../assets/images/Main/Form/main-form.webp'
import ImageWithShadow from '../../ImageWithShadow/ImageWithShadow';

function Help() {
    return (
        <div className="help">
            <div className='help__form-box'>
                <div className='help__form-container'>
                    <h4 className='help__form-title'>Не знаете какой выбрать потолок?</h4>
                    <p className='help__form-subtitle'>Наши специалисты ответят на все ваши вопросы и помогут подобрать подходящий вариант</p>
                    <Form
                        className="help__form"
                        col_key={'translit_name'}
                        col_value={'vibor-potalka'}
                        col_type={'base_tasks'}
                    />
                </div>
            </div>
            <div className='help__img-box'>
                <ImageWithShadow className={'help__img'} src={img} alt="" />
            </div>
        </div>
    );
}

export default Help;
