

import { useEffect, useState } from 'react';
import mainApi from '../../../assets/api/MainApi';
import Button from '../../Button/Button';
import FullScreenImage from '../../FullScreenImage/FullScreenImage';
import MiniPreloader from '../../MiniPreloader/MiniPreloader';
import ReviewsCards from '../ReviewsCards/ReviewsCards';
import './AllReviews.css';
import Stars from './Stars/Stars';

const REVIEWS_LOAD_LIMIT = 5


function AllReviews() {

    const [isPreloaderVisible, setPreloaderVisible] = useState(true)
    const [raiting, setRaiting] = useState(5)
    const [isCardsPreloaderVisible, setCardsPreloaderVisible] = useState(true)
    const [reviews, setReviews] = useState(undefined)
    const [isMore, setIsMore] = useState(false)
    useEffect(() => {
        setPreloaderVisible(true)
        setCardsPreloaderVisible(true)
        mainApi.getRaiting()
            .then((res) => {
                console.log(res.value)
                setRaiting(Number(res.value).toFixed(1))
            })
            .catch((err) => {
                console.log(err)
            })
            .finally(() => {
                setPreloaderVisible(false)
            })

        mainApi.getReviews({ limit: REVIEWS_LOAD_LIMIT })
            .then((res) => {
                console.log(res.data)
                setReviews(res.data)
                setIsMore(res.is_more)
            })
            .catch((err) => {
                console.log(err)
            })
            .finally(() => {
                setCardsPreloaderVisible(false)
            })
    }, [])

    const [isMorePreloaderVisible, setMorePreloaderVisible] = useState(false)
    function handleLoadMore() {
        setMorePreloaderVisible(true)
        const last_id = reviews[reviews.length - 1]._id
        mainApi.getReviews({ limit: REVIEWS_LOAD_LIMIT, last_id: last_id })
            .then((res) => {
                console.log(res.data)
                setReviews(prevState => prevState.concat(res.data))
                setIsMore(res.is_more)
            })
            .catch((err) => {
                console.log(err)
            })
            .finally(() => {
                setMorePreloaderVisible(false)
            })
    }
    const [fullScreenSrc, setFullScreenSrc] = useState(undefined)
    return (
        <>
            {isPreloaderVisible ?
                <div className='all-reviews__preloader'>
                    <MiniPreloader isBig={true} />
                </div>
                :
                <div className="all-reviews">
                    <FullScreenImage src={fullScreenSrc} setSrc={setFullScreenSrc} />
                    <div className='all-reviews__heading'>
                        <div className='all-reviews__main-info'>
                            <h3 className='all-reviews__title'>Отзывы</h3>
                            <div className='all-reviews__rating'>
                                <Stars raiting={raiting} />
                                <p className='all-reviews__rating-value '>{raiting.toLocaleString('us')} рейтинг</p>
                            </div>
                        </div>
                        <Button
                            className={'all-reviews__add'}
                            text={'Написать отзыв'}
                            isBgContrast={true}
                            path={'/reviews/add'}
                        />
                    </div>
                    {reviews && reviews.length > 0 ?
                        < ReviewsCards setFullScreenSrc={setFullScreenSrc} className={'all-reviews__cards'} isLoading={isCardsPreloaderVisible} items={reviews} />
                        :
                        <p className='all-reviews__no-reviews'>Отзывов пока нет. Будьте первым, кто оставит отзыв!</p>
                    }

                    {isMore ?
                        <Button
                            text={'Загрузить ещё'}
                            isBgTransparent={true}
                            className={'all-reviews__more'}
                            onClick={handleLoadMore}
                            isPreloaderVisible={isMorePreloaderVisible}
                        />
                        : null}
                </div>}
        </>
    );
}

export default AllReviews;
