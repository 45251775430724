
import { useEffect, useState } from 'react';
import mainApi from '../../../assets/api/MainApi';
import MiniPreloader from '../../MiniPreloader/MiniPreloader';
import ServicesCards from '../ServicesCards/ServicesCards';
import './AllServices.css';


function AllServices({ isDefault }) {
    const [cards, setCards] = useState(undefined)
    const [isPreloaderVisible, setPreloaderVisible] = useState(true)
    useEffect(() => {
        setPreloaderVisible(true)
        mainApi.getCards({ main_type: 'services', limit: 20 })
            .then((res) => {
                console.log(res.data)
                setCards(res.data)
            })
            .catch((err) => {
                console.log(err)
            })
            .finally(() => {
                setPreloaderVisible(false)
            })
    }, [])

    return (
        <div className={`all-services ${!isDefault ? 'all-services__block' : ''}`}>
            <h4 className='all-services__title'>Наши услуги</h4>
            {isPreloaderVisible ?
                <div className='all-services__preloader'>
                    <MiniPreloader />
                </div>
                :
                <div className='all-services__cards'>
                    <ServicesCards isAll={true} cards={cards} />
                </div>
            }

        </div>
    );
}

export default AllServices;
