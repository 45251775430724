
import { useEffect, useState } from 'react';
import mainApi from '../../../assets/api/MainApi';
import MiniPreloader from '../../MiniPreloader/MiniPreloader';
import MaterialCards from '../MaterialCards/MaterialCards';
import './AnotherMaterials.css';


function AnotherMaterials({ isDefault, exclude_id }) {
    const [cards, setCards] = useState(undefined)
    const [isPreloaderVisible, setPreloaderVisible] = useState(true)
    useEffect(() => {
        setPreloaderVisible(true)
        mainApi.getCards({ main_type: 'materials', limit: 20, exclude_id: exclude_id })
            .then((res) => {
                console.log(res.data)
                setCards(res.data)
            })
            .catch((err) => {
                console.log(err)
            })
            .finally(() => {
                setPreloaderVisible(false)
            })
    }, [])

    return (
        <div className={`another-materials ${!isDefault ? 'another-materials__block' : ''}`}>
            <h4 className='another-materials__title'>Смотрите также</h4>
            {isPreloaderVisible ?
                <div className='another-materials__preloader'>
                    <MiniPreloader />
                </div>
                :
                <div className='another-materials__cards'>
                    <MaterialCards cards={cards} />
                </div>
            }

        </div>
    );
}

export default AnotherMaterials;
