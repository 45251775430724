
import { Link } from 'react-router-dom';

import './ClientsCard.css';


function ClientsCard({ item }) {

    return (
        <Link className={`clients-card`} to={`/works/${item.translit_name}`}>
            <p className='clients-card__title'>{item.small_title}</p>
            <img className='clients-card__img' src={item.main_photo} alt={item.small_title} />
            <p className='clients-card__link' >Подробнее</p>
        </Link>
    );
}

export default ClientsCard;
