import certificate_1 from '../images/About/Certificats/1.webp'
import certificate_2 from '../images/About/Certificats/2.webp'
import certificate_3 from '../images/About/Certificats/3.webp'
import certificate_4 from '../images/About/Certificats/4.webp'
import certificate_5 from '../images/About/Certificats/5.webp'
import certificate_6 from '../images/About/Certificats/6.webp'
import certificate_7 from '../images/About/Certificats/7.webp'
import certificate_8 from '../images/About/Certificats/8.webp'
import certificate_9 from '../images/About/Certificats/9.webp'
import certificate_10 from '../images/About/Certificats/10.webp'
import certificate_11 from '../images/About/Certificats/11.webp'
import certificate_12 from '../images/About/Certificats/12.webp'
import certificate_13 from '../images/About/Certificats/13.webp'
import certificate_14 from '../images/About/Certificats/14.webp'
import certificate_15 from '../images/About/Certificats/15.webp'
import certificate_16 from '../images/About/Certificats/16.webp'
import certificate_17 from '../images/About/Certificats/17.webp'
import certificate_18 from '../images/About/Certificats/18.webp'
import certificate_19 from '../images/About/Certificats/19.webp'
import certificate_20 from '../images/About/Certificats/20.webp'
import certificate_21 from '../images/About/Certificats/21.webp'
import certificate_22 from '../images/About/Certificats/22.webp'
import certificate_23 from '../images/About/Certificats/23.webp'
import certificate_24 from '../images/About/Certificats/24.webp'
import certificate_25 from '../images/About/Certificats/25.webp'
import certificate_26 from '../images/About/Certificats/26.webp'
import certificate_27 from '../images/About/Certificats/27.webp'
import certificate_28 from '../images/About/Certificats/28.webp'
import certificate_29 from '../images/About/Certificats/29.webp'
import certificate_31 from '../images/About/Certificats/31.webp'
import certificate_30 from '../images/About/Certificats/30.webp'


export const CERTIFICATES = [
    certificate_1,
    certificate_2,
    certificate_3,
    certificate_4,
    certificate_5,
    certificate_6,
    certificate_7,
    certificate_8,
    certificate_9,
    certificate_10,
    certificate_11,
    certificate_12,
    certificate_13,
    certificate_14,
    certificate_15,
    certificate_16,
    certificate_17,
    certificate_18,
    certificate_19,
    certificate_20,
    certificate_21,
    certificate_22,
    certificate_23,
    certificate_24,
    certificate_25,
    certificate_26,
    certificate_27,
    certificate_28,
    certificate_29,
    certificate_30,
    certificate_31,
]