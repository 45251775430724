import black_star1 from '../images/About/clients/black_star/1.webp'
import black_star2 from '../images/About/clients/black_star/2.webp'
import black_star3 from '../images/About/clients/black_star/3.webp'
import black_star4 from '../images/About/clients/black_star/4.webp'
import black_star5 from '../images/About/clients/black_star/5.webp'

import world_class1 from '../images/About/clients/world_class/1.webp'
import world_class2 from '../images/About/clients/world_class/2.webp'
import world_class3 from '../images/About/clients/world_class/3.webp'
import world_class4 from '../images/About/clients/world_class/4.webp'
import world_class5 from '../images/About/clients/world_class/5.webp'
import world_class6 from '../images/About/clients/world_class/6.webp'

export const OUR_CLIENTS = [
    {
        small_title: 'Black Star Fitness',
        translit_name: 'black-star-fitness',
        photos: [
            black_star1,
            black_star2,
            black_star3,
            black_star4,
            black_star5,
        ],
        main_photo: black_star1,
    },
    {
        small_title: 'World Class',
        translit_name: 'world_class',
        photos: [
            world_class1,
            world_class2,
            world_class3,
            world_class4,
            world_class5,
            world_class6,
        ],
        main_photo: world_class1,
    },
]