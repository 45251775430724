import React, { useState, useEffect, useRef } from 'react';

const LazyImage = ({ src, alt, className, onClick }) => {
    const [imageSrc, setImageSrc] = useState(null);
    const imageRef = useRef();

    useEffect(() => {
        const ref = imageRef.current
        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    setImageSrc(src);
                    observer.unobserve(ref);
                }
            });
        });
        observer?.observe(ref);
        return () => {
            observer?.unobserve(ref);
        };
    }, [src]);

    return (
        <img
            src={imageSrc}
            alt={alt}
            ref={imageRef}
            className={className}
            onClick={onClick}
 // Set appropriate height and width of the image
        />
    );
};

export default LazyImage