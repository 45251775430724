
import { useEffect, useState } from 'react';
import mainApi from '../../../assets/api/MainApi';
import MiniPreloader from '../../MiniPreloader/MiniPreloader';
import TypeCards from '../TypeCards/TypeCards';
import './AllTypes.css';


function AllTypes({ isDefault }) {
    const [cards, setCards] = useState(undefined)
    const [isPreloaderVisible, setPreloaderVisible] = useState(true)
    useEffect(() => {
        setPreloaderVisible(true)
        mainApi.getCards({ main_type: 'ceilings', limit: 20 })
            .then((res) => {
                console.log(res.data)
                setCards(res.data)
            })
            .catch((err) => {
                console.log(err)
            })
            .finally(() => {
                setPreloaderVisible(false)
            })
    }, [])

    return (
        <div className={`all-types ${!isDefault ? 'all-types__block' : ''}`}>
            <h4 className='all-types__title'>Виды потолков</h4>
            {isPreloaderVisible ?
                <div className='all-types__preloader'>
<MiniPreloader />
                </div>
                :
                <div className='all-types__cards'>
                    <TypeCards isAll={true} cards={cards} />
                </div>
            }

        </div>
    );
}

export default AllTypes;
