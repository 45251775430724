import { MAIN_URL } from "../utils/constants";

class MainApi {
  // constructor({ baseUrl }) {
  //   MAIN_URL = baseUrl;
  // }

  _checkResponse(res) {
    if (res.ok) {
      return res.json();
    } else {
      const statusCode = res.status;
      return res.json().then((res) => {
        return Promise.reject({
          statusCode: statusCode,
          message: res.msg,
          detail: res.detail,
        });
      });
    }
  }

  _checkResponseWithCookies({ res, func, params }) {
    if (res.ok) {
      return res.json();
    } else {
      const statusCode = res.status;
      return res.json().then((res) => {
        if (statusCode === 422 && res.detail === "Signature has expired") {
          console.log("ss");
          return mainApi
            .refreshJWT()
            .then((res) => {
              return func(params);
            })
            .catch((err) => {
              return Promise.reject(err);
            });
        } else {
          return Promise.reject({
            statusCode: statusCode,
            message: res.msg,
            detail: res.detail,
          });
        }
      });
    }
  }

  refreshJWT() {
    return fetch(`${MAIN_URL}/users/refresh-jwt`, {
      method: "POST",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }).then(this._checkResponse);
  }

  getCards({ last_id, limit, main_type, exclude_id }) {
    let params = {}
    if (last_id) params.last_id = last_id
    if (limit) params.limit = limit
    if (main_type) params.main_type = main_type
    if (exclude_id) params.exclude_id = exclude_id

    return fetch(`${MAIN_URL}/main/get-all?` + new URLSearchParams(params), {
      method: 'GET',
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
    }).then(this._checkResponse);
  };

  getItem({ type, main_type, value }) {
    let params = {}
    if (value) params.value = value
    if (type) params.type = type
    if (main_type) params.main_type = main_type


    return fetch(`${MAIN_URL}/main/get-by-type?` + new URLSearchParams(params), {
      method: 'GET',
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
    }).then(this._checkResponse);
  };

  sendContactForm({ name, phone, time, col_key, col_value, col_type }) {
    return fetch(`${MAIN_URL}/form/send`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        name: name,
        phone: phone,
        time: time,
        col_key: col_key,
        col_value: col_value,
        col_type: col_type,
      }
      )
    }).then(this._checkResponse)
  };

  getRaiting() {
    return fetch(`${MAIN_URL}/reviews/get-average-score`, {
      method: 'GET',
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
    }).then(this._checkResponse);
  };

  getReviews({ last_id, limit }) {
    let params = {}
    if (last_id) params.last_id = last_id
    if (limit) params.limit = limit


    return fetch(`${MAIN_URL}/reviews/get-all?` + new URLSearchParams(params), {
      method: 'GET',
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
    }).then(this._checkResponse);
  };

  async sendReview({ data }) {
    return fetch(`${MAIN_URL}/reviews/send`, {
      method: "POST",
      credentials: "include",
      body: data,
    }).then(this._checkResponse);
  }
}


const mainApi = new MainApi({
  baseUrl: MAIN_URL,
});

export default mainApi;
