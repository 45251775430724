
import ServicesCard from './ServicesCard/ServicesCard';
import './ServicesCards.css';


function ServicesCards({ isAll, cards }) {

    return (
        <div className={`service-cards ${isAll ? 'service-cards__all' : ''}`}>
            {cards && cards.length > 0 ?
                cards.map((item, i) => (
                    <ServicesCard item={item} />
                ))
                : null}
        </div>
    );
}

export default ServicesCards;
