

import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { OUR_CLIENTS } from '../../assets/utils/our_clients';
import { OUR_WORKS } from '../../assets/utils/our_works';
import FullScreenImage from '../FullScreenImage/FullScreenImage';
import LazyImage from '../LazyImage/LazyImage';
import './Works.css';


function Works() {
    const { translit_name } = useParams()
    const [work, setWork] = useState(undefined)
    const [fullScreenSrc, setFullScreenSrc] = useState(undefined)

    useEffect(() => {
        if (!translit_name) return
        const ALL_WORKS = [].concat(OUR_CLIENTS).concat(OUR_WORKS)
        const filteredItem = ALL_WORKS.filter((item) => item.translit_name === translit_name)[0]
        console.log(filteredItem)
        setWork(filteredItem)
    }, [translit_name])
    return (
        <div className="works">
            <FullScreenImage src={fullScreenSrc} setSrc={setFullScreenSrc} />
            {work ?
                <div className='works__block-with-padding'>

                    <h3 className='works__title'>{work.small_title}</h3>
                    <div className='works__cards'>
                        {work.photos.map((item, i) => (
                            <LazyImage
                                onClick={(e) => {
                                    // e.target?.requestFullscreen()
                                    setFullScreenSrc(item)
                                }}
                                className="works__card" src={item} alt="" />
                        ))}

                    </div>
                </div>
                : null}


        </div>
    );
}

export default Works;
