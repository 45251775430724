import Button from '../../Button/Button';
import './Banner.css';

import mainBanner from '../../../assets/images/Main/Banner/main-banner.webp'
import ImageWithShadow from '../../ImageWithShadow/ImageWithShadow';

function Banner({ openFormPopup }) {
    return (
        <div className="banner">
            <div className='banner__info'>
                <h2 className='banner__title'>Натяжные потолки <br />от 150 р/м2</h2>
                <p className='banner__subtitle'>Под ключ. Сегодня замерим - завтра установим.</p>
                <div className='banner__btns'>
                    <Button
                        isBgContrast={true}
                        text="Вызвать замерщика"
                        onClick={() => {
                            openFormPopup({
                                title: 'Вызов замерщика',
                                col_key: 'translit_name',
                                col_value: 'vizov-zamershika',
                                col_type: 'base_tasks',
                            })
                        }}
                    />
                    <Button
                        isBgTransparent={true}
                        text="Рассчитать стоимость"
                        onClick={() => {
                            openFormPopup({
                                title: 'Расчёт стоимости',
                                col_key: 'translit_name',
                                col_value: 'raschet-stoimosti',
                                col_type: 'base_tasks',
                            })
                        }}
                    />
                </div>
            </div>
            <ImageWithShadow className='banner__img' src={mainBanner} alt='' />
        </div>
    );
}

export default Banner;
