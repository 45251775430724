
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { LogoIcon, PhoneIcon } from '../../assets/icons/icons';
import { CONTACT_PHONES, MAIN_CONTACT_PHONE } from '../../assets/utils/constants';
import './Header.css';
import HeaderLinks from './HeaderLinks/HeaderLinks';
import MenuPopup from './MenuPopup/MenuPopup';





function Header() {
    const [popups, setPopups] = useState({
        menuPopup: false,
    })

    function hendleMenuOpen() {
        setPopups(prevState => ({
            ...prevState,
            menuPopup: true,
        }))
    }
    return (
        <header className="header">
            <MenuPopup isOpen={popups.menuPopup} setOpen={setPopups} popupName={'menuPopup'} />
            <div className='header__content'>
                <Link className='header__logo-box' to={"/"}>
                    <LogoIcon mainClassName={'header__logo'} fillClassName={'header__logo-fill'} />
                </Link>
                <div className='header__links header__links_pc'>
                    <HeaderLinks />
                    <div className='header__contacts'>
                        {CONTACT_PHONES.map((item, i) => (
                            <a className='header__contact' href={`tel:${item.number}`} key={`header__contact${i}`}>{item.display_value}</a>
                        ))}
                    </div>
                </div>
                <div className='header__links header__links_mobile'>
                    <a className='header__phone-box' href={`tel:${MAIN_CONTACT_PHONE.number}`}>
                        {/* <div className='header__phone'>
                            <PhoneIcon mainClassName={'header__phone-icon'} strokeClassName={'header__phone-icon-stroke'} />
                        </div> */}
                        {MAIN_CONTACT_PHONE.display_value}
                    </a>
                    <button className='header__burger' type='button' onClick={hendleMenuOpen}>
                        <div className='header__burger-line'></div>
                        <div className='header__burger-line'></div>
                        <div className='header__burger-line'></div>
                    </button>
                </div>
            </div>
        </header>
    );
}

export default Header;
