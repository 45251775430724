
import TypeCard from './TypeCard/TypeCard';
import './TypeCards.css';


function TypeCards({ isAll, cards }) {

    return (
        <div className={`type-cards ${isAll ? 'type-cards__all' : ''}`}>
            {cards && cards.length > 0 ?
                cards.map((item, i) => (
                    <TypeCard item={item} />
                ))
                : null}
        </div>
    );
}

export default TypeCards;
