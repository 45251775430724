


import MiniPreloader from '../../MiniPreloader/MiniPreloader';
import ReviewCard from './ReviewCard/ReviewCard';
import './ReviewsCards.css';


function ReviewsCards({ items, className, isLoading, setFullScreenSrc }) {

    return (
        <div className={`reviews-cards ${className ? className : ''}`}>
            {isLoading ?
                <div className='reviews-cards__preloader'>
                    <MiniPreloader />
                </div> :
                <>
                    {items && items.length > 0 ? items.map((item, i) => (
                        <ReviewCard setFullScreenSrc={setFullScreenSrc} item={item} />
                    )) : null}
                </>}
        </div>
    );
}

export default ReviewsCards;
