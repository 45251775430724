

import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Mousewheel } from "swiper";

import './Slider.css';
import "swiper/css";
import "swiper/css/pagination";
import { useState } from "react";
import { ControllIcon } from "../../../assets/icons/icons";
import FullScreenImage from "../../FullScreenImage/FullScreenImage";
import LazyImage from "../../LazyImage/LazyImage";

function Slider({ photosArray, className }) {
    const [my_swiper, set_my_swiper] = useState({});
    const [fullScreenSrc, setFullScreenSrc] = useState(undefined)

    return (
        <div className={`slider ${className ? className : ''}`}>
            <FullScreenImage src={fullScreenSrc} setSrc={setFullScreenSrc} />
            <div className="slider__controll" onClick={() => {
                if (my_swiper) {
                    my_swiper.slidePrev();
                }
            }}>
                <ControllIcon mainClassName={'slider__controll-icon'} strokeClassName={'slider__controll-icon-stroke'} />
            </div>
            <Swiper
                slidesPerView={"auto"}
                spaceBetween={0}
                pagination={{
                    clickable: true,
                    dynamicBullets: true,
                    dynamicMainBullets: 4,
                }}
                mousewheel={{
                    forceToAxis: true,
                }}

                modules={[Mousewheel, Pagination]}
                preventInteractionOnTransition={true}
                className="slider__cards"
                onInit={(ev) => {
                    set_my_swiper(ev)
                }}

            >
                {photosArray.map((item, i) => (
                    <SwiperSlide className="slider__card" key={`slider__card${i}`}>
                        <LazyImage
                            onClick={(e) => {
                                // e.target?.requestFullscreen()
                                setFullScreenSrc(item)
                            }}
                            className="slider__card-img" src={item} alt="" />
                    </SwiperSlide>
                ))}


            </Swiper>
            <div className="slider__controll" onClick={() => {
                if (my_swiper) {
                    my_swiper.slideNext();
                }
            }}>
                <ControllIcon mainClassName={'slider__controll-icon slider__controll-icon_next'} strokeClassName={'slider__controll-icon-stroke'} />
            </div>
        </div>
    );
}

export default Slider;
