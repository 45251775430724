
import ClientsCard from './ClientsCard/ClientsCard';
import './ClientsCards.css';


function ClientsCards({ isAll, cards }) {
    const slicedCards = isAll ? cards : cards.slice(0, 3)
    return (
        <div className={`clients-cards ${isAll ? 'clients-cards__all' : ''}`}>
            {slicedCards && slicedCards.length > 0 ?
                slicedCards.map((item, i) => (
                    <ClientsCard item={item} />
                ))
                : null}
        </div>
    );
}

export default ClientsCards;
