import { getMatirealIcon } from '../../../assets/icons/matirealIcons';
import { MAIN_URL } from '../../../assets/utils/constants';
import Button from '../../Button/Button';
import ImageWithShadow from '../../ImageWithShadow/ImageWithShadow';
import './MaterealInfo.css';


function MaterealInfo({ item , openFormPopup}) {
    const Icon = getMatirealIcon(item.svg_type)

    return (
        <div className="material-info">
            <div className='material-info__texts'>
                <h3 className='material-info__title'>{item.full_title}</h3>
                <p className='material-info__text'>{item.description}</p>
                <p className='material-info__price'>{item.price}</p>
                <Button
                    text={item.button_text}
                    className="material-info__btn"
                    isBgContrast={true}
                    onClick={() => {
                        openFormPopup({
                            title: item.popup_title,
                            col_key: '_id',
                            col_value: item._id,
                            col_type: 'materials',
                        })
                    }}
                />
            </div>
            <Icon mainClassName="material-info__image" />
            {/* <ImageWithShadow className={'material-info__image'} src={`${MAIN_URL}/get-file/${item.photo_path}`} alt="" /> */}
        </div>
    );
}

export default MaterealInfo;
