import { OUR_WORKS } from '../../../../assets/utils/our_works';

import ClientsCards from '../ClientsCards/ClientsCards';

import './AllWorks.css';


function AllWorks() {


    return (
        <div className={`all-works`}>
            <h4 className='all-works__title'>Наши работы</h4>
            <ClientsCards cards={OUR_WORKS} isAll={true} />
        </div>
    );
}

export default AllWorks;
