
import { useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import AboutUs from '../AboutUs/AboutUs';
import AllWorks from '../AboutUs/OurClients/AllWorks/AllWorks';
import Contacts from '../Contacts/Contacts';
import Footer from '../Footer/Footer';
import FormPopup from '../FormPopup/FormPopup';
import Header from '../Header/Header';
import Main from '../Main/Main';
import Matereal from '../Matereal/Matereal';
import Reviews from '../Reviews/Reviews';
import Service from '../Service/Service';
import Services from '../Services/Services';
import Type from '../Type/Type';
import Types from '../Types/Types';
import Works from '../Works/Works';
import './App.css';

function App() {
  const [popups, setPopups] = useState({
    form: false,
  })
  const [formObj, setFormObj] = useState(undefined)

  function openFormPopup({ title, col_key, col_value, col_type }) {
    if (!title || !col_key || !col_value || !col_type) return
    setFormObj({
      title: title,
      col_key: col_key,
      col_value: col_value,
      col_type: col_type,
    })
    setPopups(prevState => ({
      ...prevState,
      form: true
    }))
  }
  return (
    <div className="app">
      <FormPopup isOpen={popups.form} setOpen={setPopups} popupName="form" formObj={formObj} setFormObj={setFormObj} />
      <Header />
      <div className='app__content'>
        <Routes>
          <Route path='/' end index element={<Main openFormPopup={openFormPopup} />}></Route>
          <Route path='/contacts' element={<Contacts />}></Route>
          <Route path='/ceiling-types' element={<Types />}></Route>
          <Route path='/ceiling-services' element={<Services />}></Route>
          <Route path='/ceiling-type/:translit_name' element={<Type openFormPopup={openFormPopup} />}></Route>
          <Route path='/ceiling-material/:translit_name' element={<Matereal openFormPopup={openFormPopup} />}></Route>
          <Route path='/ceiling-service/:translit_name' element={<Service openFormPopup={openFormPopup} />}></Route>
          <Route path='/reviews/*' element={<Reviews />}></Route>
          <Route path='/about-us' element={<AboutUs />}></Route>
          <Route path='/works/:translit_name' element={<Works />}></Route>
          <Route path='/all-works' element={<AllWorks />}></Route>

        </Routes>
      </div>
      <Footer />
    </div>
  );
}

export default App;
