
import { Navigate, Route, Routes } from 'react-router-dom';
import AllReviews from './AllReviews/AllReviews';
import NewReview from './NewReview/NewReview';
import './Reviews.css';


function Reviews() {

    return (
        <div className="reviews">
            <Routes>
                <Route index element={<Navigate to={'all'} />}></Route>
                <Route path='all' element={<AllReviews />}></Route>
                <Route path='add' element={<NewReview />}></Route>
            </Routes>
        </div>
    );
}

export default Reviews;
