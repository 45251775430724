import { Link } from 'react-router-dom';
import { LogoIcon } from '../../assets/icons/icons';
import { CONTACT_PHONES, MAIN_LINKS } from '../../assets/utils/constants';
import './Footer.css';


function FooterLinks({ isMobile }) {
    return (
        <div className={`footer__links-box ${isMobile ? 'footer__links-box_mobile' : 'footer__links-box_pc'}`}>
            {MAIN_LINKS.map((item, i) => (
                <Link
                    className={"footer__link"}
                    key={`footer__link${i}${isMobile ? '-mobile' : ''}`}
                    to={`/${item.path}`}>
                    {item.name}
                </Link>
            ))}


        </div>
    )
}

function Footer() {
    return (
        <footer className="footer">
            <div className="footer__content">
                <div className="footer__first-row">
                    <Link className='footer__logo-box' to={"/"}>
                        <LogoIcon mainClassName={'footer__logo'} fillClassName={'footer__logo-fill'} />
                    </Link>
                    <div className='footer__links'>
                        <FooterLinks />
                        <div className='footer__contacts'>
                            {CONTACT_PHONES.map((item, i) => (
                                <a className='footer__contact' href={`tel:${item.number}`} key={`footer__contact${i}`}>{item.display_value}</a>
                            ))}
                        </div>
                    </div>
                </div>
                <FooterLinks isMobile={true} />
                <div className='footer__additional'>
                    <div className='footer__additional-texts'>
                        {/* <p className='footer__additional-text'>© ИП Лихачев Е.Е</p> */}
                        <p className='footer__additional-text'>Все права защищены правообладателем</p>
                    </div>
                    {/* <div className='footer__additional-texts'>

                        <p className='footer__additional-text'>Политика конфиденциальности</p>
                    </div> */}
                </div>
            </div>
        </footer>
    );
}

export default Footer;