import dvuhurovneviy1 from '../images/About/OurWorks/dvuhurovneviy/1.webp'
import dvuhurovneviy2 from '../images/About/OurWorks/dvuhurovneviy/2.webp'
import dvuhurovneviy3 from '../images/About/OurWorks/dvuhurovneviy/3.webp'
import dvuhurovneviy4 from '../images/About/OurWorks/dvuhurovneviy/4.webp'
import dvuhurovneviy5 from '../images/About/OurWorks/dvuhurovneviy/5.webp'
import dvuhurovneviy6 from '../images/About/OurWorks/dvuhurovneviy/6.webp'
import dvuhurovneviy7 from '../images/About/OurWorks/dvuhurovneviy/7.webp'
import dvuhurovneviy8 from '../images/About/OurWorks/dvuhurovneviy/8.webp'
import dvuhurovneviy9 from '../images/About/OurWorks/dvuhurovneviy/9.webp'
import dvuhurovneviy10 from '../images/About/OurWorks/dvuhurovneviy/10.webp'
import dvuhurovneviy11 from '../images/About/OurWorks/dvuhurovneviy/11.webp'

import paryashie1 from '../images/About/OurWorks/paryashie/1.webp'
import paryashie2 from '../images/About/OurWorks/paryashie/2.webp'
import paryashie3 from '../images/About/OurWorks/paryashie/3.webp'
import paryashie4 from '../images/About/OurWorks/paryashie/4.webp'
import paryashie5 from '../images/About/OurWorks/paryashie/5.webp'
import paryashie6 from '../images/About/OurWorks/paryashie/6.webp'
import paryashie7 from '../images/About/OurWorks/paryashie/7.webp'
import paryashie8 from '../images/About/OurWorks/paryashie/8.webp'
import paryashie9 from '../images/About/OurWorks/paryashie/9.webp'
import paryashie10 from '../images/About/OurWorks/paryashie/10.webp'
import paryashie11 from '../images/About/OurWorks/paryashie/11.webp'
import paryashie12 from '../images/About/OurWorks/paryashie/12.webp'
import paryashie13 from '../images/About/OurWorks/paryashie/13.webp'
import paryashie14 from '../images/About/OurWorks/paryashie/14.webp'

import mansarda1 from '../images/About/OurWorks/mansarda/1.webp'
import mansarda2 from '../images/About/OurWorks/mansarda/2.webp'
import mansarda3 from '../images/About/OurWorks/mansarda/3.webp'
import mansarda4 from '../images/About/OurWorks/mansarda/4.webp'
import mansarda5 from '../images/About/OurWorks/mansarda/5.webp'
import mansarda6 from '../images/About/OurWorks/mansarda/6.webp'

import lineinie_svetilniki1 from '../images/About/OurWorks/lineinie_svetilniki/1.webp'
import lineinie_svetilniki2 from '../images/About/OurWorks/lineinie_svetilniki/2.webp'
import lineinie_svetilniki3 from '../images/About/OurWorks/lineinie_svetilniki/3.webp'

import paryashie_s_korniznoi_nichey1 from '../images/About/OurWorks/paryashie_s_korniznoi_nichey/1.webp'
import paryashie_s_korniznoi_nichey2 from '../images/About/OurWorks/paryashie_s_korniznoi_nichey/2.webp'
import paryashie_s_korniznoi_nichey3 from '../images/About/OurWorks/paryashie_s_korniznoi_nichey/3.webp'
import paryashie_s_korniznoi_nichey4 from '../images/About/OurWorks/paryashie_s_korniznoi_nichey/4.webp'
import paryashie_s_korniznoi_nichey5 from '../images/About/OurWorks/paryashie_s_korniznoi_nichey/5.webp'
import paryashie_s_korniznoi_nichey6 from '../images/About/OurWorks/paryashie_s_korniznoi_nichey/6.webp'
import paryashie_s_korniznoi_nichey7 from '../images/About/OurWorks/paryashie_s_korniznoi_nichey/7.webp'

import photopechat1 from '../images/About/OurWorks/photopechat/1.webp'
import photopechat2 from '../images/About/OurWorks/photopechat/2.webp'
import photopechat3 from '../images/About/OurWorks/photopechat/3.webp'
import photopechat4 from '../images/About/OurWorks/photopechat/4.webp'
import photopechat5 from '../images/About/OurWorks/photopechat/5.webp'
import photopechat6 from '../images/About/OurWorks/photopechat/6.webp'
import photopechat7 from '../images/About/OurWorks/photopechat/7.webp'
import photopechat8 from '../images/About/OurWorks/photopechat/8.webp'
import photopechat9 from '../images/About/OurWorks/photopechat/9.webp'
import photopechat10 from '../images/About/OurWorks/photopechat/10.webp'
import photopechat11 from '../images/About/OurWorks/photopechat/11.webp'
import photopechat12 from '../images/About/OurWorks/photopechat/12.webp'
import photopechat13 from '../images/About/OurWorks/photopechat/13.webp'

import svetovie_linii1 from '../images/About/OurWorks/svetovie_linii/1.webp'
import svetovie_linii2 from '../images/About/OurWorks/svetovie_linii/2.webp'
import svetovie_linii3 from '../images/About/OurWorks/svetovie_linii/3.webp'
import svetovie_linii4 from '../images/About/OurWorks/svetovie_linii/4.webp'
import svetovie_linii5 from '../images/About/OurWorks/svetovie_linii/5.webp'
import svetovie_linii6 from '../images/About/OurWorks/svetovie_linii/6.webp'
import svetovie_linii7 from '../images/About/OurWorks/svetovie_linii/7.webp'
import svetovie_linii8 from '../images/About/OurWorks/svetovie_linii/8.webp'

export const OUR_WORKS = [
    {
        small_title: 'Двухуровневый потолок',
        translit_name: 'dvuhurovneviy-potolok',
        photos: [
            dvuhurovneviy1,
            dvuhurovneviy2,
            dvuhurovneviy3,
            dvuhurovneviy4,
            dvuhurovneviy5,
            dvuhurovneviy6,
            dvuhurovneviy7,
            dvuhurovneviy8,
            dvuhurovneviy9,
            dvuhurovneviy10,
            dvuhurovneviy11,
        ],
        main_photo: dvuhurovneviy1,
    },
    {
        small_title: 'Парящие потолки',
        translit_name: 'paryashie',
        photos: [
            paryashie1,
            paryashie2,
            paryashie3,
            paryashie4,
            paryashie5,
            paryashie6,
            paryashie7,
            paryashie8,
            paryashie9,
            paryashie10,
            paryashie11,
            paryashie12,
            paryashie13,
            paryashie14,
        ],
        main_photo: paryashie1,
    },
    {
        small_title: 'Мансарда',
        translit_name: 'mansarda',
        photos: [
            mansarda1,
            mansarda2,
            mansarda3,
            mansarda4,
            mansarda5,
            mansarda6,
        ],
        main_photo: mansarda1,
    },
    {
        small_title: 'Линейные светильники',
        translit_name: 'lineinie-svetilniki',
        photos: [
            lineinie_svetilniki1,
            lineinie_svetilniki2,
            lineinie_svetilniki3,
        ],
        main_photo: lineinie_svetilniki1,
    },
    {
        small_title: 'Парящие с карнизной нишей',
        translit_name: 'paryashie-s-korniznoi-nichey',
        photos: [
            paryashie_s_korniznoi_nichey1,
            paryashie_s_korniznoi_nichey2,
            paryashie_s_korniznoi_nichey3,
            paryashie_s_korniznoi_nichey4,
            paryashie_s_korniznoi_nichey5,
            paryashie_s_korniznoi_nichey6,
            paryashie_s_korniznoi_nichey7,
        ],
        main_photo: paryashie_s_korniznoi_nichey1,
    },
    {
        small_title: 'Фотопечать',
        translit_name: 'photopechat',
        photos: [
            photopechat1,
            photopechat2,
            photopechat3,
            photopechat4,
            photopechat5,
            photopechat6,
            photopechat7,
            photopechat8,
            photopechat9,
            photopechat10,
            photopechat11,
            photopechat12,
            photopechat13,
        ],
        main_photo: photopechat1,
    },
    {
        small_title: 'Световые линии',
        translit_name: 'svetovie-linii',
        photos: [
            svetovie_linii1,
            svetovie_linii2,
            svetovie_linii3,
            svetovie_linii4,
            svetovie_linii5,
            svetovie_linii6,
            svetovie_linii7,
            svetovie_linii8,
        ],
        main_photo: svetovie_linii8,
    },
]