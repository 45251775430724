

import { StarIcon } from '../../../../assets/icons/icons';
import './Stars.css';


function Stars({ raiting, isOnCard, selectValue }) {

    return (
        <div className="stars">
            {Array.from({ length: 5 }).map((item, i) => (
                <div className={`${selectValue ? 'stars__icon-select' : ''}`} onClick={() => {
                    if (selectValue) {
                        selectValue(i + 1)
                    }
                }}>
                    <StarIcon mainClassName={'stars__icon'} fillClassName={i + 1 > raiting ? 'stars__icon-fill-anim' : 'stars__icon-fill stars__icon-fill-anim'} strokeClassName={'stars__icon-stroke'} />
                </div>
            ))}


        </div>
    );
}

export default Stars;
